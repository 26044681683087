import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DrawIcon from "@mui/icons-material/Draw";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import DifferenceIcon from "@mui/icons-material/Difference";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { downloadFile, inDevInfo } from "../../utils/helper";
import { RequestHelper } from "../../utils/requestHelper";
import {
  _ACTIONS,
  _ANNULE,
  _A_SIGNER,
  _BROUILLON,
  _EN_COURS_SIGNATURE,
  _MESSAGES,
  _SIGNE,
  _SWAL_ICONS,
} from "../../utils/_constants";
import { createConfirm } from "../../utils/confirm";
import { createToast } from "../../utils/toast";
import _ from "lodash";

function MandatAction({ params, refreshGrid }) {
  const navigate = useNavigate();
  const requestHelper = new RequestHelper();
  // TODO gérer les actions en fonction des status
  const handleDelete = (id) => {
    createConfirm("Voulez vous annuler ce mandat ?")
      .fire({})
      .then((result) => {
        if (result.isConfirmed) {
          requestHelper
            .doRequest(_ACTIONS.PUT, `/api/mandats/${id}`, {
              statut: "/api/statuts/5",
            })
            .then((response) => {
              if (response.status === 200) {
                createToast(
                  _SWAL_ICONS.success,
                  _MESSAGES.mandat_abort_success
                );
                refreshGrid();
              }
            });
        }
      });
  };

  const createAvenant = () => {
    createConfirm("Voulez vous créer un avenant à partir de ce mandat ?")
      .fire({})
      .then((result) => {
        if (result.isConfirmed) {
          requestHelper
            .doRequest(_ACTIONS.POST, `/api/create-avenant`, {
              mandatOrigine: `${params.row.id}`,
            })
            .then((response) => {
              if (response.data.success) {
                navigate(`/mandat-avenant/${response.data.avenant}`);
              }
            });
        } else {
          refreshGrid();
        }
      });
  };

  const duplicateMandat = () => {
    createConfirm("Voulez vous dupliquer ce mandat ?")
      .fire({})
      .then((result) => {
        if (result.isConfirmed) {
          requestHelper
            .doRequest(_ACTIONS.POST, `/api/duplicate-mandat`, {
              mandatOrigine: `${params.row.id}`,
            })
            .then((response) => {
              if (response.data.success) {
                navigate(`/mandat-${response.data.type}/${response.data.id}`);
              } else {
                createToast(_SWAL_ICONS.error, response.data.message);
              }
            });
        } else {
          refreshGrid();
        }
      });
  };

  const goToSignature = () => {
    createConfirm("Voulez vous envoyer ce mandat en signature ?")
      .fire({})
      .then((result) => {
        if (result.isConfirmed) {
          requestHelper
            .doRequest(_ACTIONS.PUT, `/api/mandats/${params.row.id}`, {
              statut: "/api/statuts/3",
            })
            .then((response) => {
              if (response.status === 200) {
                createToast(
                  _SWAL_ICONS.success,
                  _MESSAGES.mandat_tosign_success
                );
                // Navigate to signature page
                processSignature();
              }
            });
        }
      });
  };

  const processSignature = () => {
    navigate(`/process-sign/${params.row.id}`);
  };

  const downloadSignDoc = () => {
    const requestId = params.row.signRequests.map((sign) => {
      if (sign.requestStatus === 4) {
        return sign.requestId;
      }
    });

    if (_.isEmpty(requestId)) {
      requestHelper
        .doRequest(_ACTIONS.POST, `/api/download-signed-doc`, {
          mandatId: params.row.id,
        })
        .then((response) => {
          if (response.data.success) {
            downloadFile("document.pdf", response.data.file);
          } else {
            createToast(_SWAL_ICONS.error, response.data.message);
          }
        });
    } else {
      requestHelper
        .doRequest(_ACTIONS.POST, `/api/download-signed-doc`, {
          requestId: _.isEmpty(requestId) ? null : requestId[0],
        })
        .then((response) => {
          if (response.data.success) {
            downloadFile("document.pdf", response.data.file);
          } else {
            createToast(_SWAL_ICONS.error, response.data.message);
          }
        });
    }
  };

  const renderActionsCell = (params) => {
    let p = params;
    let mode = p.row.type;
    switch (p.row.statut.nom) {
      case _BROUILLON:
        return (
          <>
            <strong>
              <EditIcon
                titleAccess="Modifier le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(`/mandat-${mode}/` + p.row.id, "_blank")
                }
              ></EditIcon>
            </strong>
            <strong>
              <CancelIcon
                titleAccess="Annuler le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete(p.row.id)}
              ></CancelIcon>
            </strong>
            <strong>
              <ContentCopyIcon
                titleAccess="Dupliquer le mandat"
                sx={{ cursor: "pointer" }}
                onClick={duplicateMandat}
              ></ContentCopyIcon>
            </strong>
          </>
        );
      case _ANNULE:
        return (
          <>
            <strong>
              <ContentCopyIcon
                titleAccess="Dupliquer le mandat"
                sx={{ cursor: "pointer" }}
                onClick={duplicateMandat}
              ></ContentCopyIcon>
            </strong>
            <strong>
              <EditIcon
                titleAccess="Modifier le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(`/mandat-${mode}/` + p.row.id, "_blank")
                }
              ></EditIcon>
            </strong>
          </>
        );
      case _A_SIGNER:
        return (
          <>
            <strong>
              <EditIcon
                titleAccess="Modifier le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(`/mandat-${mode}/` + p.row.id, "_blank")
                }
              ></EditIcon>
            </strong>
            <strong>
              <CancelIcon
                titleAccess="Annuler le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete(p.row.id)}
              ></CancelIcon>
            </strong>
            <strong>
              <ContentCopyIcon
                titleAccess="Dupliquer le mandat"
                sx={{ cursor: "pointer" }}
                onClick={duplicateMandat}
              ></ContentCopyIcon>
            </strong>
            <strong>
              <DrawIcon
                titleAccess="Envoyer en signature"
                sx={{ cursor: "pointer" }}
                onClick={goToSignature}
              ></DrawIcon>
            </strong>
          </>
        );
      case _SIGNE:
        return (
          <>
            <strong>
              <EditIcon
                titleAccess="Modifier le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(`/mandat-${mode}/` + p.row.id, "_blank")
                }
              ></EditIcon>
            </strong>
            <strong>
              <ContentCopyIcon
                titleAccess="Dupliquer le mandat"
                sx={{ cursor: "pointer" }}
                onClick={duplicateMandat}
              ></ContentCopyIcon>
            </strong>
            <strong>
              <SimCardDownloadIcon
                titleAccess="Télécharger le mandat signé"
                sx={{ cursor: "pointer" }}
                onClick={downloadSignDoc}
              ></SimCardDownloadIcon>
            </strong>
            {mode === "vente" && (
              <strong>
                <DifferenceIcon
                  titleAccess="Créer un avenant au mandat"
                  sx={{ cursor: "pointer" }}
                  onClick={createAvenant}
                ></DifferenceIcon>
              </strong>
            )}
          </>
        );
      case _EN_COURS_SIGNATURE:
        return (
          <>
            <strong>
              <EditIcon
                titleAccess="Modifier le mandat"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(`/mandat-${mode}/` + p.row.id, "_blank")
                }
              ></EditIcon>
            </strong>
            <strong>
              <ContentCopyIcon
                titleAccess="Dupliquer le mandat"
                sx={{ cursor: "pointer" }}
                onClick={duplicateMandat}
              ></ContentCopyIcon>
            </strong>
            <strong>
              <FactCheckIcon
                titleAccess="Valider les signatures"
                sx={{ cursor: "pointer" }}
                onClick={processSignature}
              ></FactCheckIcon>
            </strong>
          </>
        );
    }
  };

  return (
    <>
      <Box>{renderActionsCell(params)}</Box>
    </>
  );
}

export default MandatAction;
